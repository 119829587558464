import { createContext, useContext, useEffect, useMemo, useState } from "react";
import type { ReactNode } from "react";
const MatchMediaContext = createContext({} as {
  [query: string]: boolean;
});
export const useMatchMedia = (mediaQuery: string) => {
  const context = useContext(MatchMediaContext);
  const {
    [mediaQuery]: contextValue
  } = context;
  const [matches, setMatches] = useState<boolean>();
  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQuery);
    setMatches(mediaQueryList.matches);
    const handleChange = ({
      matches
    }: MediaQueryListEvent) => setMatches(matches);
    mediaQueryList.addEventListener("change", handleChange);
    return () => mediaQueryList.removeEventListener("change", handleChange);
  }, [mediaQuery]);
  return contextValue ?? matches;
};
export const OverrideMatchMedia = ({
  children,
  matches,
  mediaQuery
}: {
  children: ReactNode;
  matches?: boolean;
  mediaQuery: string;
}) => {
  const prevQueries = useContext(MatchMediaContext);
  const queries = useMemo(() => matches === undefined ? prevQueries : {
    ...prevQueries,
    [mediaQuery]: matches
  }, [matches, mediaQuery, prevQueries]);
  return <MatchMediaContext.Provider value={queries} data-sentry-element="unknown" data-sentry-component="OverrideMatchMedia" data-sentry-source-file="use-match-media.tsx">
      {children}
    </MatchMediaContext.Provider>;
};